import { Box, Button, Text } from "grommet";

import { messagesCommon } from "../messages/messagesCommon";
import { useResponsive } from "../responsive";

export const BoutonsInscription = (props) => {
    const smallTheme = {
        buttonMargin: { top: "small", left: "medium", right: "medium" },
    };

    const regularTheme = {
        buttonMargin: { top: "small" },
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    return (
        <Box direction="column">
            <Box direction="column" pad="small">
                <Text color="red" size="small">
                    ** : Champs obligatoires pour enregistrement
                </Text>
                <Text color="red" size="small">
                    * : Champs obligatoires pour soumission
                </Text>
            </Box>
            <Box direction="row-responsive" gap="small">
                <Button
                    type="button"
                    label={messagesCommon.SUPPRIMER}
                    primary
                    disabled={props.disableButtons}
                    margin={responsiveTheme.buttonMargin}
                    onClick={() => props.clickSupprimer()}
                />
                <Button
                    type="button"
                    label={messagesCommon.ENREGISTRER}
                    primary
                    disabled={props.disableButtons}
                    margin={responsiveTheme.buttonMargin}
                    onClick={() => props.clickEnregistrer()}
                />
                <Button
                    type="submit"
                    label={
                        props.formulaireValide === false
                            ? messagesCommon.INVALID_FORM
                            : messagesCommon.SOUMETTRE
                    }
                    primary
                    margin={responsiveTheme.buttonMargin}
                    disabled={props.formulaireValide === false || props.disableButtons}
                />
            </Box>
        </Box>
    );
};
