import { Checkmark, Close, More } from "grommet-icons";
import { theme } from "../theme";

export const themeKO = {
    backgroundColor: "sicmired",
    logo: <Close role="img" color="white" />,
    border: false,
    dividerColor: theme.global.colors.sicmired,
    doted: false,
    headerColor: "black"
};

export const themeOK = {
    backgroundColor: "sicmigreen",
    logo: <Checkmark role="img" color="white" />,
    border: false,
    dividerColor: theme.global.colors.sicmigreen,
    doted: false,
    headerColor: "black"
};

export const themeCurrent = {
    backgroundColor: "sicmigreen",
    logo: <More role="img" color="white" />,
    border: false,
    dividerColor: theme.global.colors.sicmigreen,
    doted: true,
    headerColor: "black"
};

export const themePending = {
    backgroundColor: "white",
    logo: <More role="img" color="sicmigrey" />,
    border: { size: "medium" },
    dividerColor: theme.global.colors.sicmigrey,
    doted: true,
    headerColor: "sicmigrey",
};

const themeDone = (logo, doted) => {
    return  {backgroundColor: "sicmiblack",
    border: false,
    logo: logo,
    dividerColor: theme.global.colors.sicmiblack,
    doted: doted,
    headerColor: "sicmiblack"};
}

export const themeDoneOK = (() => {
    return themeDone(<Checkmark role="img" color="white" />, false);
})();

export const themeDoneKO = (() => {
    return themeDone(<Close role="img" color="white" />, false);
})();

export const themeDonePending = (() => {
    return themeDone(<More role="img" color="white" />, true);
})();

export const getTheme = (type, header, body) => {
    switch(type) {
        case "ko":
            return {theme: themeKO, header: header, body: body};
        case "ok":
            return {theme: themeOK, header: header, body: body};
        case "pending":
            return {theme: themePending, header: header, body: body};
        case "current":
            return {theme: themeCurrent, header: header, body: body};
        case "doneOK":
            return {theme: themeDoneOK, header: header, body: body};
        case "doneKO":
            return {theme: themeDoneKO, header: header, body: body};
        case "donePending":
            return {theme: themeDonePending, header: header, body: body};
        default:
            return {theme: themePending, header: header, body: body};
    }
};