import { Box, Button, Heading, Paragraph, Spinner, Text } from "grommet";
import { useEffect, useState } from "react";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { useHistory } from "react-router-dom";
import { useResponsive } from "../responsive";
import { agentInscriptions } from "./agentInscriptions";
import { paths } from "../paths";

export const PostValidation = (props) => {
    const [recapitulatif, setRecapitulatif] = useState(undefined);

    const history = useHistory();

    const smallTheme = {
        width: "100%",
    };

    const regularTheme = {
        width: "60%",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    useEffect(() => {
        agentInscriptions.getPostValidationContent(
            props.idSession,
            (result) => {
                setRecapitulatif(result);
            },
            (error) => {
                console.log(error);
                history.push(paths.ERROR_401);
            }
        );
    }, [props.idSession]);

    return (
        <Box align="center">
            {!recapitulatif && (
                <Box margin={{top: "10em", bottom: "10em"}}>
                    <Spinner size="large" color="sicmiblue" />
                </Box>
                    
                )}
            {recapitulatif && (
                <Box
                    direction="column"
                    pad={{ top: "small", bottom: "small" }}
                    margin={{ top: "medium", bottom: "medium" }}
                    width={responsiveTheme.width}
                    gap="medium"
                    align="center"
                >
                    <Heading color="neutral-1" level="2">
                        {messagesInscriptionSession.DOSSIER_VALIDE_HEADER.replace(
                            "DATE",
                            recapitulatif.dateValidation
                        ).replace("HEURE", recapitulatif.timeValidation)}
                    </Heading>
                    {recapitulatif.messageBureau && (
                        <Paragraph textAlign="center" fill responsive>{recapitulatif.messageBureau}</Paragraph>
                    )}
                    {recapitulatif.messages?.length > 0 &&
                        recapitulatif.messages.map((item, i) => (
                            <Paragraph margin="none" textAlign="center" fill key={i}>{item}</Paragraph>
                        ))}
                    {recapitulatif.messageJuridique && (
                        <Text margin="small" weight="bold" >{recapitulatif.messageJuridique}</Text>
                    )}
                    <Button
                        label={messagesInscriptionSession.GO_ESPACE_CANDIDAT}
                        margin={{ top: "small" }}
                        onClick={() => history.push(paths.ESPACE_CANDIDAT)}
                        primary
                    />
                </Box>
            )}
        </Box>
    );
};
