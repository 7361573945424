import { FormField } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const TextFormField = (props) => {
    const { label, required, requiredForSave, italicLabel, name, ...rest } =
        props;
    return (
        <FormField
            label={
                <FormFieldLabel
                    label={label}
                    required={required}
                    requiredForSave={requiredForSave}
                    italicLabel={italicLabel}
                />
            }
            required={required}
            name={name}
            htmlFor={name}
            a11yTitle={`${label} ${
                !requiredForSave && required
                    ? " (Obligatoire pour soumission)"
                    : ""
            }${requiredForSave ? " (Obligatoire pour enregistrement)" : ""}`}
            {...rest}
        />
    );
};
