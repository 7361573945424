import { Box, Heading, Text } from "grommet";

import { messagesCommon } from "../messages/messagesCommon";
import { RoutedAnchor } from "../anchor/RoutedAnchor";

export const ErrorBody = (props) => {
    return (
            <Box
                direction="column"
                fill="vertical"
                pad={{ top: "small", bottom: "large" }}
                width="53%"
                alignSelf="center"
            >
                <Heading level="3" color="red">{ messagesCommon.ERROR }</Heading>
                <Text margin={{bottom: "medium"}}>{props.text}</Text>
                <RoutedAnchor
                        to={props.returnPath}
                        label={props.returnLabel}
                        size="medium"
                />
            </Box>
    );
};
