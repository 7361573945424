import { Box, Grommet, Main } from "grommet";
import { useState, useEffect } from "react";

import { messagesContact } from "../messages/messagesContact";
import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { paths } from "../paths";
import { agentContact } from "./agentContact";
import { AdministrationContact } from "./AdministrationContact";

export const Contact = () => {
    const breadcrumbs = [
        { label: "Accueil", href: paths.HOME },
        { label: "Contact" },
    ];

    const [administrations, setAdministrations] = useState([]);

    useEffect(() => {
        agentContact.getAdministrations((content) =>
            setAdministrations(content)
        );
    }, []);

    return (
        <Grommet full theme={theme}>
            <HeaderSicmi
                title={messagesContact.PAGE_TITLE}
                breadcrumbs={breadcrumbs}
            />
            <Main>
                <Box flex overflow="auto">
                    {administrations.map((administration) =>
                        administration.id !== 0 &&
                        administration.valide === 1 &&
                        Array.isArray(administration.trefBurRecruts) &&
                        administration.trefBurRecruts.length ? (
                            <AdministrationContact
                                key={administration.id}
                                administration={administration}
                            />
                        ) : null
                    )}
                </Box>
            </Main>

            <FooterSicmi />
        </Grommet>
    );
};
