import { store } from "./store";

export const FetchHandler = {
    getJsonForLoggedUser: (
        path: string,
        callback: Function,
        errorCallback: Function = undefined
    ) => {
        const authToken =
            store.connected === true ? "Bearer " + store.token : "";
        fetch(path, {
            method: "GET",
            headers: {
                Authorization: authToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status.toString());
                }
                return response.json();
            })
            .then((data) => {
                callback(data);
            })
            .catch((error) => {
                if (errorCallback) {
                    errorCallback(error.message);
                } else {
                    console.log("no callback", error);
                }
            });
    },
};
