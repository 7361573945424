import { Link } from "react-router-dom";
import { Text } from 'grommet';

import styles from "./routedanchor.module.css";

export const RoutedAnchor = (props) => {

    const textSize = props.size ? props.size : "medium";
    const classNames =
        props.color && props.color === "black"
            ? `${styles.routedAnchor} ${styles.routedAnchorBlack}`
            : `${styles.routedAnchor} ${styles.routedAnchorBlue}`;

    return (
        <Link to={props.to} className={classNames}>
            <Text size={textSize}>{props.label}</Text>
        </Link>
    );
};
