import { Box, Button, Heading, RadioButtonGroup, Text } from "grommet";
import { useEffect, useState } from "react";
import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { useResponsive } from "../responsive";

export const ChoixBureau = (props) => {
    const [selectedBureau, setSelectedBureau] = useState(undefined);
    const [listBureaux, setListBureaux] = useState(undefined);

    const smallTheme = {
        width: "80%",
    };

    const regularTheme = {
        width: "40%",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    useEffect(() => {
        if (props.bureauxParticipants?.length > 0) {
            setListBureaux(
                props.bureauxParticipants.map((b) => {
                    let bureau: any = {};
                    bureau.id = b.trefBurRecrutId.toString();
                    bureau.label = b.libelle;
                    bureau.value = b.trefBurRecrutId.toString();
                    bureau.disabled = false;
                    return bureau;
                })
            );
        }
    }, [props.bureauxParticipants]);

    return (
        <Box align="center">
        <Box
            direction="column"
            pad={{ top: "small", bottom: "small" }}
            margin={{ top: "medium", bottom: "medium" }}
            width={responsiveTheme.width}
            gap="medium"
            align="center"
        >
            <Heading level="3">
                {messagesInscriptionSession.SELECT_BUREAU_RECRUTEMENT}
            </Heading>
            <Text>{props.message}</Text>
            <Box gap="medium" align="center" overflow="auto">
                {listBureaux && (
                    <RadioButtonGroup
                        name="radio"
                        options={listBureaux}
                        value={selectedBureau}
                        onChange={(event) =>
                            setSelectedBureau(event.target.value)
                        }
                    />
                )}
                
            </Box>
            <Button
                    label="Valider"
                    primary
                    disabled={!selectedBureau}
                    onClick={() => props.onValidation(selectedBureau)}
                />
        </Box>
        </Box>
    );
};
