import { Box, Button, Text, Tip } from "grommet";
import { CircleInformation } from "grommet-icons";

import { messagesInscriptionSession } from "../messages/messagesInscriptionSession";
import { useResponsive } from "../responsive";

export const SessionInfo = (props) => {
    const smallTheme = {
        infoWidth: "100%",
    };

    const regularTheme = {
        infoWidth: "60%",
    };

    const responsiveTheme = useResponsive(smallTheme, regularTheme);

    const recrut = props.session
        ? `${props.session.trefGradeRecrut.libelle} - ${props.session.trefCategRecrut.libelle} - ${props.session.trefTypeRecrut.libelle}`
        : null;
    const administration = props.session
        ? props.session.trefAdministration.libelle
        : null;
    const dateFin = props.session
        ? new Date(props.session.limiteDepotDossierInternet)
        : null;

    return (
        props.session && (
            <Box align="center">
                <Box
                    direction="column"
                    pad={{ top: "small", bottom: "small" }}
                    margin={{ top: "medium", bottom: "medium" }}
                    gap="small"
                    width={responsiveTheme.infoWidth}
                >
                    <Box width={responsiveTheme.infoWidth}>
                        <Box
                            direction="row"
                            justify="between"
                            fill="horizontal"
                            pad="small"
                            gap="small"
                        >
                            <Text weight="bold">
                                {
                                    messagesInscriptionSession.ADMINISTRATION_EMPLOI
                                }
                            </Text>
                            <Text textAlign="end">{administration}</Text>
                        </Box>
                        <Box
                            direction="row"
                            justify="between"
                            fill="horizontal"
                            pad="small"
                            gap="small"
                        >
                            <Text weight="bold">
                                {messagesInscriptionSession.RECRUTEMENT}
                            </Text>
                            <Text textAlign="end">{recrut}</Text>
                        </Box>
                        <Box
                            direction="row"
                            justify="between"
                            fill="horizontal"
                            pad="small"
                            gap="small"
                        >
                            <Text weight="bold">
                                {messagesInscriptionSession.FIN_INSCRIPTION}
                            </Text>
                            {dateFin && (
                                <Box direction="row" gap="small">
                                    <Text textAlign="end">
                                        {`${dateFin.toLocaleDateString()} à ${dateFin.toLocaleTimeString(
                                            [],
                                            {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            }
                                        )}`}
                                    </Text>
                                    <Tip
                                        content={
                                            <Box
                                                pad="small"
                                                gap="small"
                                                width={{ max: "small" }}
                                            >
                                                <Text
                                                    a11yTitle="Information"
                                                    weight="bold"
                                                >
                                                    Information
                                                </Text>
                                                <>
                                                    <Text
                                                        a11yTitle={
                                                            messagesInscriptionSession.INFO_SESSION_END
                                                        }
                                                    >
                                                        {
                                                            messagesInscriptionSession.INFO_SESSION_END
                                                        }
                                                    </Text>
                                                </>
                                            </Box>
                                        }
                                        dropProps={{ align: { left: "right" } }}
                                    >
                                        <Button
                                            plain
                                            icon={<CircleInformation a11yTitle={
                                                messagesInscriptionSession.INFO_SESSION_END
                                            } />}
                                        />
                                    </Tip>
                                </Box>
                            )}
                        </Box>
                        {props.session?.messageInscription && (
                            <Box pad="small" gap="small">
                                <Text>{props.session.messageInscription}</Text>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        )
    );
};
