export const setCookie = (name, value, lifespan) => {
    
    let d = new Date(1970);
    if (lifespan > 0) {
        d = new Date();
        d.setTime(d.getTime() + (lifespan*1000));
    }
    
    document.cookie = name+"="+value+"; expires="+d.toUTCString() + ";path=/";
};

export const getCookie = (key) => {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return null;
};