import { CheckBoxGroup, FormField } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const CheckboxGroupFormField = (props) => {
    const { label, required, name, options, labelKey, valueKey, italicLabel, onChange, clear, ...rest } = props;

    return (
        <FormField
            label={<FormFieldLabel label={label} required={required} italicLabel={italicLabel} />}
            required={required}
            name={name}
            htmlFor={name}
            a11yTitle={label}
            {...rest}
        >
            <CheckBoxGroup
                name={name}
                id={name}
                labelKey={labelKey}
                valueKey={valueKey}
                options={options}
            />
        </FormField>
    );
};
