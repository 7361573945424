import { FormField, MaskedInput } from "grommet";

import { FormFieldLabel } from "./FormFieldLabel";

export const MaskedTextFormField = (props) => {
    const { label, required, requiredForSave, italicLabel, name, ...rest } =
        props;

    // Voir les SFD pour la définition textuelle de ces règles
    //S6 Iso : sur la v6 de SICMI, les virgules ne sont pas autorisées mais dans la spec de la S6 oui
    // Erratum : on rajoute les accents mais ils seront supprimés plus tard dans la méthode d'uppercase
    // de FormulaireInscription.tsx
    const rules = {
        S1: "^[a-zA-ZÀ-ÿ '\\-]{minLength,maxLength}$",
        S2: "^[a-zA-ZÀ-ÿ0-9 '\\-.]{minLength,maxLength}$",
        S6: "^[a-zA-ZÀ-ÿ0-9 '\\-.,]{minLength,maxLength}$",
        S6Iso: "^[a-zA-ZÀ-ÿ0-9 '\\-.]{minLength,maxLength}$",
        nolimit: "^.{minLength,maxLength}$"
    };

    const regexString =
        props.regexRule && rules[props.regexRule]
            ? rules[props.regexRule]
            : rules.S2;

    const regex = new RegExp(
        regexString
            .replace("minLength", props.required ? 1 : 0)
            .replace("maxLength", props.maxLength ? props.maxLength : 255)
    );

    return (
        <FormField
            label={label ?
                <FormFieldLabel
                    label={label}
                    required={required}
                    requiredForSave={requiredForSave}
                    italicLabel={italicLabel}
                /> : undefined
            }
            required={required}
            name={name}
            htmlFor={name}
            a11yTitle={`${label} ${
                !requiredForSave && required
                    ? " (Obligatoire pour soumission)"
                    : ""
            }${requiredForSave ? " (Obligatoire pour enregistrement)" : ""}`}
            {...rest}
        >
            <MaskedInput mask={[{ regexp: regex }]} id={name} name={name} />
        </FormField>
    );
};
