import { Text } from "grommet";

import { messagesEspaceCandidat } from "../../messages/messagesEspaceCandidat";

export const LocaleDate = (props) => {
    let dateFormatted = props.textPrefix;

    if (props.date) {
        const date = new Date(Date.parse(props.date));
        const options = { year: "numeric", month: "long", day: "numeric" };
        let lastDate = props.lastDate ? new Date(Date.parse(props.lastDate)) : undefined;

        if (dateFormatted) {
            dateFormatted += " " + date.toLocaleDateString(props.locale, options);
        } else {
            dateFormatted = date.toLocaleDateString(props.locale, options);
        }

        if (lastDate) {
            dateFormatted += messagesEspaceCandidat.TO + lastDate.toLocaleDateString(props.locale, options);
        }
    }

    return (
        <Text size="medium" color={props.color} weight={props.weight ? props.weight : "bold"}>
            {dateFormatted}
        </Text>
    );
};
