import { useContext } from "react";
import { ResponsiveContext } from "grommet";

export const useResponsive = (smallTheme, regularTheme, mediumTheme = undefined) => {
    const sizes = {small: smallTheme, large: regularTheme, medium: mediumTheme};

    const size = useContext(ResponsiveContext);
    return (sizes[size]) ? sizes[size] : regularTheme;
};

export const useScreenSize = () => {

    return useContext(ResponsiveContext);
};
