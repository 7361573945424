export const store = {
    bearer: {},
    token: "",
    connected: false,
    nom: "",
    prenom: "",
    email: "",
    fc_token_id: "",
    compte_id: "",
    csrfToken: ""
};