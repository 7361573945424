import { Box, Heading} from 'grommet';

import { BlocsTextesAccueil } from './BlocsTextesAccueil';

export const Decouvrir = () => {
    return (
        <Box 
            gap="small"
            pad={{right: "xlarge", left: "xlarge"}}
            align="center"
            fill
        >
            <Box align="center"><Heading size="small">Découvrir les métiers du Ministère de l'Intérieur</Heading></Box>
            <BlocsTextesAccueil></BlocsTextesAccueil>
        </Box>
    );
};