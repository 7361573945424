export const messagesAutresRens = {
    SITU_FAMILLE: "Situation familiale",
    NB_ENFANTS: "Nombre d'enfants",
    NB_ENFANTS_CHARGE: "Nombre d'enfants à charge",
    NB_HANDICAP_CHARGE: "Nombre de personnes handicapées à charge",
    PROFESSION: "Profession",
    NUM_PRO: "Numéro de téléphone professionnel",
    EMAIL_PRO: "Email professionnel",
    SITU_MILITAIRE: "Situation militaire",
    NB_ENGAGEMENT_DRAPEUX_ANNEES: "Nombre d'années d'engagement sous les drapeaux",
    NB_ENGAGEMENT_DRAPEUX_MOIS: "Nombre de mois d'engagement sous les drapeaux",
    MAX_DIPLOME: "Diplôme le plus élevé",
    EN_COURS: "En cours",
    ACQUIS: "Acquis",
    NOM_PERE: "Nom du père",
    PROFESSION_PERE: "Profession du père (dernière activité si décédé)",
    NOM_MERE: "Nom de la mère",
    PROFESSION_MERE: "Profession de la mère (dernière activité si décédé)",
    PERSONNE_HANDI: "Personne handicapée",
    TRAVAILLEUR_HANDI: "Travailleur handicapé",
    ANCIEN_TRAVAILLEUR_HANDI: "Ancien travailleur handicapé",
    SPORTIF_HL: "Sportif de haut niveau",
    ANCIEN_SPORTIF_HL: "Ancien sportif de haut niveau",
    POSITION_ADMIN: "Position administrative",
    ADMINISTRATION_EMPLOI: "Administration d'emploi",
    LAST_ADMINISTRATION_EMPLOI: "Ou dernière administration d'emploi",
    DIRECTION_AFFECTATION: "Direction d'affectation",
    AFFECTATION_ADMINISTRATIVE: "Affectation administrative",
    ADRESSE_AFFECTATION: "Adresse d'affectation",
    CODE_POSTAL: "Code postal",
    COMMUNE: "Commune",
    PAYS_AFFECTATION: "Pays d'affectation",
    TEL_PRO: "Téléphone professionnel",
    GRADE: "Grade",
    LAST_GRADE: "Ou dernier grade",
    PRECISER_GRADE: "Préciser votre grade si nécessaire",
    DATE_NOMINATION_GRADE: "Date de nomination dans le grade (sous la forme JJ/MM/AAAA)",
    DATE_ENTREE_FP: "Date d’entrée dans la fonction publique (sous la forme JJ/MM/AAAA)",
    DATE_TITULARISATION_FP: "Date de titularisation dans la fonction publique (ou de signature du contrat) (sous la forme JJ/MM/AAAA)",
    DATE_ENTREE_PN: "Date d’entrée dans la police nationale (sous la forme JJ/MM/AAAA)",
    MATRICULE: "Matricule (sur 7 chiffres par exemple 0999999)",
    CONDITIONS_PARTICIPATION: "Conditions de participation",
    DIPLOME_MAX: "Diplôme le plus élevé",
    AMENAGEMENTS: "Aménagement(s) demandé(s)",
    DUREE_ENGAGEMENT_DRAPEAUX: "Durée d'engagement sous les drapeaux",
};