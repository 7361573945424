import { Text } from "grommet";
import { useEffect, useState } from "react";
import { RoutedAnchor } from "../anchor/RoutedAnchor";

import { store } from "../auth/store";

import { messagesListeSessions } from "../messages/messagesListeSessions";

export const BoutonInscriptionResponsive = (props) => {
    
    const [canRegister, setCanRegister] = useState(false);

    useEffect(() => {
        setCanRegister(props.session.inscriptionPossible);
    }, [props]);

    return (
        store.connected && canRegister ? (
                <RoutedAnchor to={`/inscription/${props.session.tsessionId}`} label={messagesListeSessions.INSCRIRE} />
        ) : (
                <Text>
                    {
                            canRegister
                                ? messagesListeSessions.NEED_ACCOUNT
                                : messagesListeSessions.NO_INSCR
                        }
                </Text>
        )
    );
};
