import { Box, Text, Tip } from "grommet";
import { Link } from "react-router-dom";
import { UserAdd } from "grommet-icons";
import { useEffect, useState } from "react";

import { store } from "../auth/store";

import { messagesListeSessions } from "../messages/messagesListeSessions";

export const BoutonInscription = (props: any) => {
    
    const [canRegister, setCanRegister] = useState(false);

    useEffect(() => {
        setCanRegister(props.session.inscriptionPossible);
    }, [props]);

    return (
        store.connected && canRegister ? (
            <Tip
                content={
                    <Text color="white">
                        {messagesListeSessions.INSCRIRE}
                    </Text>
                }
            >
                <Link to={`/inscription/${props.session.tsessionId}`}>
                    <UserAdd
                        color="black"
                        a11yTitle={messagesListeSessions.INSCRIRE}
                    />
                </Link>
            </Tip>
        ) : (
            <Tip
                content={
                    <Text color="white">
                        {canRegister
                            ? messagesListeSessions.NEED_ACCOUNT
                            : messagesListeSessions.NO_INSCR}
                    </Text>
                }
            >
                <Box>
                    <UserAdd
                        color="black"
                        opacity=".3"
                        a11yTitle={
                            canRegister
                                ? messagesListeSessions.NEED_ACCOUNT
                                : messagesListeSessions.NO_INSCR
                        }
                    />
                </Box>
            </Tip>
        )
    );
};
