import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Anchor,
    Box,
    Button,
    Form,
    MaskedInput,
    Text,
    TextInput,
    ThemeContext,
    Image,
} from "grommet";

import { messagesCommon } from "../messages/messagesCommon";

import { agentLogin } from "../auth/agentLogin";
import { config } from "../config";
import { store } from "../auth/store";
import { getCookie, setCookie } from "../auth/cookieManager";
import { Connected } from "./Connected";
import { paths } from "../paths";
import { agentFranceConnect } from "../franceconnect/agentFranceConnect";
import { PasswordReset } from "./PasswordReset";
import { tag } from '../common/ATInternetService.js';

export const Login = (props) => {
    const [connected, setConnected] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [backendError, setBackendError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);

    const [loginForm, setLoginForm] = useState({ emailLogin: '', passwordLogin: '' })

    const history = useHistory();
    const location = useLocation();

    const clearConnection = useCallback(() => {
        store.connected = false;
        store.bearer = {};
        store.token = "";
        store.nom = "";
        store.prenom = "";
        store.compte_id = "";
        store.email = "";
        store.fc_token_id = "";
        setConnected(false);
        const token = getCookie(config.COOKIE_NAME);
        if (token) {
            setCookie(config.COOKIE_NAME, "", 0);
        }

        const fcToken = getCookie(config.FC_COOKIE_NAME);
        if (fcToken) {
            setCookie(config.FC_COOKIE_NAME, "", 0);
        }

        //On redirige vers la home
        history.push(paths.HOME);
    }, [history]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (store.connected) {
            if (
                location.pathname === paths.FRANCE_CONNECT_LOGOUT_CALLBACK &&
                urlParams.get("state")
            ) {
                if (
                    urlParams.get("state") ===
                    localStorage.getItem(config.DISCONNECT_STATE)
                ) {
                    localStorage.removeItem(config.DISCONNECT_STATE);
                    clearConnection();
                }
            } else {
                if (!store.nom) {
                    agentLogin.getInfosCompte(store.token, (infos) => {
                        if (!infos) {
                            //Erreur donc probablement 401 donc on nettoie
                            clearConnection();
                        } else {
                            store.nom = infos.nom;
                            store.prenom = infos.prenom;
                            store.email = infos.email;
                            store.compte_id = infos.id;

                            setConnected(true);
                        }
                    });
                } else {
                    setConnected(true);
                }
            }
        }
    }, [location.pathname, clearConnection, location.search]);

    const connect = (event) => {
        setLoading(true);
        agentLogin.login(
            event.value.emailLogin,
            event.value.passwordLogin,
            (result) => {
                if (result.access_token) {

                    tag.sendPage({
                        name: 'Connexion',
                        level1: 'Connexion'
                    });    

                    agentLogin.getInfosCompte(result.access_token, (infos) => {
                        store.bearer = result;
                        store.connected = true;
                        store.nom = infos.nom;
                        store.prenom = infos.prenom;
                        store.email = infos.email;
                        store.compte_id = infos.id;
                        store.token = result.access_token;

                        setCookie(
                            config.COOKIE_NAME,
                            result.access_token,
                            result.expires_in
                        );
                        //On force le rechargement de toute la page pour actualiser les comportements liés au fait d'être connecté ou pas
                        history.go(0);
                    });
                } else if (result === "401") {
                    setLoading(false);
                    setErrorLogin(true);
                    const timer = setTimeout(() => {
                        setErrorLogin(false);
                    }, 20000);
                    return () => clearTimeout(timer);
                } else {
                    setLoading(false);
                    setBackendError(true);
                    const timer = setTimeout(() => {
                        setBackendError(false);
                    }, 20000);
                    return () => clearTimeout(timer);
                }
            }
        );
    };

    const authorizeFranceConnect = () => {
        if (!loading) {
            agentFranceConnect.getAuthorizeUrl((data) => {
                localStorage.setItem(config.CONNECT_STATE, data.cleUtilisateur);
                window.location.replace(
                    data.url +
                        window.location.origin +
                        paths.FRANCE_CONNECT_AUTHORIZE_CALLBACK_ENCODED
                );
            });
        }
    };

    const resetPassword = () => {
        if (!loading) {
            setPasswordModal(true);
        }
    };

    const disconnect = () => {
        if (store.fc_token_id) {
            agentLogin.getFCLogoutUrl(store.token, (data) => {
                if (data.cleUtilisateur) {
                    localStorage.setItem(
                        config.DISCONNECT_STATE,
                        data.cleUtilisateur
                    );

                    let url = data.url
                        .replace("$1", store.fc_token_id)
                        .replace(
                            "$2",
                            window.location.origin +
                                paths.FRANCE_CONNECT_LOGOUT_CALLBACK_ENCODED
                        );
                    window.location.replace(url);
                } else {
                    window.alert("Une erreur s'est produite");
                }
            });
        } else {
            clearConnection();
        }
    };

    return connected ? (
        <Connected
            isEspaceCandidat={props.isEspaceCandidat}
            disconnect={disconnect}
        />
    ) : (
        <ThemeContext.Extend
            value={{
                global: {
                    focus: {
                        border: {
                            color: "none",
                        },
                    },
                },
                textInput: {
                    extend: () => `
                    background: white;
                    padding: 6px;
                    &:focus {
                    box-shadow: none;
                    border-color: initial;
                    }
                    `,
                },
                maskedInput: {
                    extend: () => `
                    background: white;
                    padding: 6px;
                    &:focus {
                    box-shadow: none;
                    border-color: initial;
                    }
                    `,
                },
            }}
        >
            <Form onSubmit={connect} value={loginForm} onChange={(nextValue) => {
                setLoginForm(nextValue);
            }}>
                <MaskedInput
                    name="emailLogin"
                    mask={[
                        { regexp: /^[\w\-_.]+$/, placeholder: "exemple" },
                        { fixed: "@" },
                        { regexp: /^[\w-]+$/, placeholder: "fournisseur" },
                        { fixed: "." },
                        { regexp: /^[\w.]+$/, placeholder: "fr" },
                    ]}
                />
                <Box margin={{ top: "xsmall" }}>
                    <TextInput
                        name="passwordLogin"
                        type={"password"}
                        placeholder={messagesCommon.PASSWORD}
                    />
                </Box>
                {errorLogin && (
                    <Text weight="bold" color="red">
                        {messagesCommon.ERROR_LOGIN}
                    </Text>
                )}
                {backendError && (
                    <Text weight="bold" color="red">
                        {messagesCommon.BACKEND_ERROR}
                    </Text>
                )}
                <Box margin={{ top: "xsmall" }}>
                    <Anchor
                        label={messagesCommon.MDP_OUBLIE}
                        onClick={() => resetPassword()}
                    />
                </Box>
                <Box>
                    <Button
                        type="submit"
                        disabled={loading}
                        label={messagesCommon.BTN_CONNECT}
                        primary
                        margin={{ top: "small" }}
                    />
                </Box>
            </Form>
            <Box direction="row-responsive" justify="between">
                <Text
                    size="large"
                    margin={{ top: "small", right: "medium" }}
                    alignSelf="center"
                    weight="bold"
                >
                    {messagesCommon.OR_CAPS}
                </Text>
                <Button
                    type="button"
                    label={messagesCommon.CREATE_ACCOUNT}
                    disabled={loading}
                    primary
                    margin={{ top: "small" }}
                    onClick={() => history.push(paths.CREATION_COMPTE)}
                />
            </Box>
            {/*
            // Pour le moment, FranceConnect est désactivé
            <Box height="1px" background="dark-1" margin={{ top: "small" }} />
            <Box
                margin={{ top: "small" }}
                height="20%"
                onClick={() => authorizeFranceConnect()}
                focusIndicator
                alignSelf="center"
            >
                <Image
                    opacity={loading ? "0.3" : undefined}
                    fit="contain"
                    a11yTitle={messagesCommon.ARIA_FRANCECONNECT}
                    src="/franceconnect-bouton.svg"
                />
                </Box>*/}
            {passwordModal && <PasswordReset onClose={setPasswordModal} />}
        </ThemeContext.Extend>
    );
};
