import { Box } from "grommet";

export const Logo = ({ children, ...rest }) => (
    <Box
      height={{ min: "xxsmall", max: "xxsmall" }}
      width={{ min: "xxsmall", max: "xxsmall" }}
      round="full"
      align="center"
      justify="center"
      {...rest}
    >
        {children}
    </Box>
);