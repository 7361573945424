import { FormField, MaskedInput } from "grommet";
import { messagesInscriptionSession } from "../../messages/messagesInscriptionSession";

import { FormFieldLabel } from "./FormFieldLabel";

export const PostalCodeFormField = (props) => {
    const {
        label,
        italicLabel,
        required,
        name,
        placeholder,
        departements,
        ...rest
    } = props;

    function validateCodePostal(value) {
        if (!value || value.length !== 5) {
            return "5" + messagesInscriptionSession.REQUIRED_DIGITS;
        }

        if (departements?.length > 0) {
            const codeDep = value.substring(0, 2).match("97|98")
                ? value.substring(0, 3)
                : value.substring(0, 2);

            const result = departements.find((d) => d.code === codeDep);
            if (!result) {
                return messagesInscriptionSession.INVALID_POSTAL_CODE;
            }

            if (props.couverture?.listeDepartements?.length > 0) {
                const limiteDep = props.couverture.listeDepartements.find(
                    (d) => d.code === codeDep && d.allowed === true
                );
                if (!limiteDep) {
                    const depSaisi = props.couverture.listeDepartements.find(
                        (d) => d.code === codeDep
                    );
                    return messagesInscriptionSession.ERROR_LIMIT_POSTCODE.replace(
                        "{0}",
                        props.couverture.burRecrutLibelle
                    ).replace("{1}", depSaisi?.libelleBurRecrut);
                }
            }
        }
    }

    return (
        <FormField
            label={
                <FormFieldLabel
                    label={label}
                    required={required}
                    italicLabel={italicLabel}
                />
            }
            required={required}
            name={name}
            a11yTitle={
                label + required ? " (Obligatoire pour soumission)" : undefined
            }
            validate={validateCodePostal}
            htmlFor={name}
            {...rest}
        >
            <MaskedInput
                mask={[
                    {
                        regexp: /^\d{1,5}$/,
                    },
                ]}
                id={name}
                name={name}
                placeholder={placeholder}
            />
        </FormField>
    );
};
