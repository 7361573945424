import { Box, Image, Heading } from "grommet";

import { messagesCommon } from '../messages/messagesCommon';
import { BreadCrumbs } from './BreadCrumbs';
import { Login } from './Login';
import { useResponsive, useScreenSize } from '../responsive';

export const HeaderSicmi = (props) => {

    const breadcrumbs = (props.breadcrumbs) ? <BreadCrumbs items={props.breadcrumbs}/> : null;

    const smallTheme = {
        loginWidth: "100%",
        loginMargin: { top: "xsmall", bottom: "large" },
        insideLoginMargin: { top: "medium", bottom: "medium" },
        direction: "column",
        
        backgroundImage: {
            "position": "center",
            "repeat": "no-repeat",
            "size": "cover",
            "image": "linear-gradient(to bottom, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%), url(/header.webp)"
        }
    }

    const regularTheme = {
        loginWidth: "40%",
        direction: "row",
        insideLoginMargin: { top: "small", bottom: "small" },
        titleWidth: "60%",
        minTitleWidth: "75em",
        loginMargin: { top: "xsmall", bottom: "xsmall" },
        backgroundImage: {
            "position": "center",
            "repeat": "no-repeat",
            "size": "cover",
            "image": "linear-gradient(to bottom, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%), url(/header.webp)"
        }
    }

    const responsiveTheme = useResponsive(smallTheme, regularTheme);
    const screenSize = useScreenSize();
    
    return (
        <Box
            align="center"
            background={responsiveTheme.backgroundImage}
            flex={false}
        >
            <Box
                direction="row-responsive"
                pad={{ right: "large", left: "large", bottom:"medium" }}
                style={{ minWidth: responsiveTheme.minTitleWidth }}
            >
                <Box
                    pad={{ right: "large", left: "large" }}
                    width={responsiveTheme.titleWidth}
                >
                    <Box direction="row-responsive">
                        <Box pad="small" >
                            <Image a11yTitle={messagesCommon.MINISTERE_INTERIEUR_LOGO} src="/logo_minint_transparent_wm.png" width="150px" />
                        </Box>
                        <Box 
                            pad="small" 
                            justify="end"
                        >
                            <Heading 
                                level="3" 
                                margin="none"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {messagesCommon.HEADER_URL}
                            </Heading>
                        </Box>
                    </Box>
                    <Heading 
                        margin={{ top: "medium", bottom: "small" }} 
                        level="2"
                    >
                        {props.title}
                    </Heading>
                    {screenSize !== "small" && breadcrumbs}
                </Box>
                <Box
                    direction="column"
                    pad={{ right: "large", left: "large" }} 
                    margin={responsiveTheme.loginMargin}
                    background={{ color: "light-2", opacity: "strong" }}
                    width={{ max: responsiveTheme.loginWidth }}
                    round
                    elevation="xlarge"
                    justify="center"
                >
                    <Box margin={responsiveTheme.insideLoginMargin} >
                        <Login isEspaceCandidat={props.isEspaceCandidat} />
                    </Box>
                </Box>
                
                {screenSize === "small" && breadcrumbs}
            </Box>
        </Box>
    );
};
