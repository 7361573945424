export const messagesListeSessions = {
    PAGE_TITLE: "Liste des sessions",
    SELECT: "Sélectionner",
    ADMINISTRATION_EMPLOI: "Administration d'emploi",
    RECRUTEMENT: "Recrutement",
    TYPE_RECRUTEMENT: "Type de recrutement",
    GRADE: "Grade",
    CORPS: "Corps",
    COMPETENCE: "Compétence",
    SPECIALITE: "Spécialité",
    BUREAU_RECRUTEMENT: "Bureau organisateur",
    ETAT_SESSION: "Etat de la session",
    ANONYMISEE: "Anonymisée",
    SOUS_SESSION_ONLY: "Sous-session uniquement",
    RECHERCHE_BTN: "Rechercher une session",   
    CLEAR: "(non sélectionné)",
    OUVERTURE_INSCRI: "Ouverture des inscriptions",
    CLOTURE_INSCRI: "Clôture des inscriptions",
    DATE_PREMIER_INSCRI: "Date des premières épreuves",
    INSCRIRE: "S'inscrire",
    NEED_ACCOUNT: "Il est nécessaire d'être connecté pour s'inscrire à une session",
    NO_INSCR: "Il est actuellement impossible de s'inscrire pour cette session",
    NO_RESULT: "Pas de résultats",
};