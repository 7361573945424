import { Box, Grommet } from "grommet";

import { HeaderSicmi } from "../common/HeaderSicmi";
import { FooterSicmi } from "../common/FooterSicmi";
import { theme } from "../theme";
import { ErrorBody } from "./ErrorBody";

export const ErrorPage = (props) => {
    return (
        <Grommet full theme={theme}>
            <Box fill>
                <HeaderSicmi
                />
                <ErrorBody text={props.text} returnPath={props.returnPath} returnLabel={props.returnLabel} />
                <FooterSicmi />
            </Box>
        </Grommet>
    );
};
